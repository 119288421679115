@font-face {
  font-family: 'Spartan';
  src: url('../assets/Spartan.ttf') format('truetype');
}

* {
  font-family: 'Spartan';
}

@font-face {
  font-family: 'Triplex';
  src: url('../assets/Triplex.ttf') format('truetype');
}

#menu-button {
  word-spacing: -1rem;
  font-size: 4rem;
  font-family: 'Triplex';
  text-transform: uppercase;
  color: black;
}
